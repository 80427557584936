.EditPage .leaflet-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.EditPage .EditPageSection {
    margin-top: 10px;
    margin-bottom: 10px;
}

.EditPage .EditPageSection button {
    margin-right: 10px;
}

.EditPage .EditPageButtons {
    display: flex;
}

.EditPage .EditPageButtons #delete-button {
    margin-left: auto;
}

.EditPage .LocationEditField {
    margin-right: 5px;
}

.EditPage .LocationVisibility {
    display: flex;
    flex-direction: row;
}

.EditPage .VisibilityLevelCaption {
    margin: auto;
    margin-left: 10px;
}