.Event {
    background-color: rgb(243, 243, 243);
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.Event a {
    color: #0078A8;
}
