.InvitationPage {
    text-align: center;
}

.InvitationPage .ContactEmail {
    margin: 20px;
}

.InvitationPage .ErrorMessage {
    margin: 20px;
}

.InvitationPage .CreateAccountSubmit {
    margin-top: 20px;
}

.InvitationPage div {
    margin: auto;
    margin-bottom: 5px;
}
