.LocationFiles .File {
    display: flex;
    min-height: 40px;
}

.LocationFiles .File .FileName {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    line-break: anywhere;
}

.LocationFiles .File .DeleteButton {
    margin-left: auto;
}

.LocationFiles .UploadFileInput {
    margin-top: 10px;
    margin-bottom: 10px;
}
