.AdminPageSection {
    margin-top: 15px;
    margin-bottom: 30px;
}

.CreateAnnouncement {
    padding: 5px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px; /* Add margin to separate buttons from the message */
  }
  
  .confirmation-popup button {
    margin-left: 12px; /* Add padding to the buttons */
  }