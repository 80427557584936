.ImageManagementCard {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-radius: 10px;
    border-color: #b4b4b4;
}

.ImageManagementCard .Section {
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
}

.ImageManagementModal {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.ImageManagementModal * {
    margin-top: 10px;
    margin-bottom: 10px;
}
