.NewMessageGroupModal {
    padding: 10px;
}

.NewMessageGroupModalButtons {
    display: flex;
}

.NewMessageGroupModalButtons #createbutton {
    margin-left: auto;
}

.NewMessageGroupModalUserSelect {
    margin: 10px;
}