.MessagesDisplaySection {
    padding-top: 10px;
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.FocusedMessageGroupDisplay {
    flex-grow: 8;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    min-width: 80%;
}

.MessagesPageMobile .FocusedMessageGroupDisplay {
    max-width: 100%;
}

.MessagesEndRef {
    margin-top: auto;
}
