.SearchDisplay {
    position: fixed;
    height: calc(100% - 64px);
    min-width: 200px;
    width: 20%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.SearchResult {
    display: flex;
    border-top: 1px;
    border-top-style: solid;
    border-color: rgb(224, 224, 224);
    padding: 10px;
}

.SearchResult:hover {
    background-color: rgb(224, 224, 224);
    cursor: pointer;
}

.SearchResultImage {
    margin-left: auto;
}

.SearchResultSectionTitle {
    margin: 10px;
    font-weight: bold;
}
