.LocationPageTitleSection {
    display: flex;
    max-width: 100%;
}

@media screen and (max-width: 800px) {
    .LocationPageTitleSection {
        flex-direction: column;
    }
}

.LocationPageTitleSection .Title {
    flex-grow: 10;
    overflow-x: hidden;
}

.LocationPageTitleSection .TitleButtons {
    margin-left: auto;
}

.PageSection {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    padding: 5px;
    border-radius: 10px;
}

.LocationPageBuffer {
    width: 100%;
    height: 20px;
}

.SectionText {
    white-space: pre-line;
    overflow-wrap: anywhere;
}

.LocationMeta {
    display: flex;
    width: 100%;
}

.LocationMeta p {
    margin: auto;
}
