.LocationImages {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    padding: 10px;
    min-height: 48px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f8f8f8;
    padding: 5px;
    border-radius: 10px;
}

.LocationImages .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 5px;
    min-height: 500px;
}

.LocationImages img {
    max-height: 500px;
    max-width: 100%;
    height: auto;
    width: auto;
    border-radius: 5px;
}

.LocationImages .Arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: rgb(152, 152, 152);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.LocationImages .Arrow:hover {
    background-color: rgba(0,0,0,0.8);
}

.LocationImages .Next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.LocationImages .Prev {
    left: 0;
    border-radius: 3px 0 0 3px;
}

.LocationImages .ImageButtons {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.LocationImages .NoImagesLabel {
    margin: 13px;
}

.LocationImages .ImageAndCaption {
    margin: auto;
}
