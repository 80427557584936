.FoundPage {
    text-align: center;
}

.FoundPage div {
    margin: auto;
    margin-bottom: 5px;
}

.FoundPage .ContactEmail {
    margin: 40px;
}

.FoundPage img {
    margin-bottom: 20px;
}