.CollectionCard {
    display: flex;
}

.CollectionCardLink {
    display: flex;
    margin-left: 0px;
    text-decoration: none;
    color: inherit;
}

.CollectionsPageTitle {
    margin-bottom: 15px;
}
