.leaflet-popup-content p {
    margin: unset;
}

.Container {
    max-width: 900px;
    padding: 24px;
    margin: auto;
}

.App {
    height: 100dvh;
}

body {
    background-color: white;
}