.MessagesPage {
    display: flex;
    max-height: calc(100% - 64px);
    min-height: calc(100% - 64px);
    max-width: 1200px;
    margin: auto;
}

.MessageGroupList {
    min-width: 20%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
}

.MessageGroupListBody {
    overflow-y: scroll;
}

.MessageGroupCard {
    background-color: #f8f8f8;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}

.MessageGroupCard:hover {
    cursor: pointer;
}

.MessageGroupList .MessageGroupCard:nth-child(even) {
    background-color: #fdfdfd;
}

.HighlightedMessageGroupCard {
    background-color: rgb(204, 242, 255) !important;
}

.NewMessageInputSection {
    display: flex;
}

.MessageRow {
    max-width: 80%;
    display: flex;
}

.SentMessageRow {
    margin-left: auto;
}

.MessageBody {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    white-space: pre-line;
    width: fit-content;
}

.SentMessage {
    text-align: right;
    margin-left: auto;
}

.SentMessage .MessageBody {
    background-color: rgb(204, 242, 255);
    margin-left: auto;
    overflow-x: hidden;
}

.NoMessagesText {
    margin: auto;
    font-size: 12px;
    text-align: center;
}

.NoMessagesTextContainer {
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    flex-grow: 1;
}

.MessageAuthor {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.MessageTime {
    font-size: 12px;
    margin: 5px;
}

.MessageGroupListHeader {
    display: flex;
}

.NewMessageGroupButton {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}

.MessageGroupUnreadDot {
    min-width: 8px;
    height: 8px;
    background-color: #ed6c02;
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
}

.MessageUnreadDot {
    min-width: 8px;
    max-width: 8px;
    height: 8px;
    background-color: #ed6c02;
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
}

.Message {
    max-width: 100%;
}

.MessageGroupDescription {
    font-size: small;
}

.MessagesEndRef {
    scroll-snap-align: end;
}
