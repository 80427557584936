.LoginPage {
    text-align: center;
}

.LoginPage div {
    margin: auto;
    margin-bottom: 5px;
}

.LoginPage .ContactEmail {
    margin: 20px;
}