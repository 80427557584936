.AppBar {
    height: 64px;
}

header div {
    margin-top: auto;
    margin-bottom: auto;
}

.AppBar .SettingsIcons {
    margin-left: auto;
}

.AppBarMenuItem {
    min-height: "54px";
    color: black;
    text-decoration: none;
}

.AppBarMenuItemIcon {
    margin-right: 8px;
}