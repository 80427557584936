.UserSettingsSection {
    margin-top: 15px;
    margin-bottom: 15px;
}

.SettingsCheckbox {
    display: flex;
}

.SettingsCheckbox p {
    margin-top: auto;
    margin-bottom: auto;
}