.LocationCollectionModal {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.CollectionCard {
    display: flex;
}

.CollectionCard * {
    margin: auto;
}

.LocationSharingModal .CollectionCards {
    margin: 10px;
    max-height: 400px;
    overflow-y: scroll;
}