.SearchInput {
    background-color: white;
    margin: auto;
    margin-left: 20px;
    padding: 4px;
    border-radius: 3px;
    max-width: 50%;
}

.SearchInput input {
    padding: 4px;
}

.LocationLeafletPopup .LocationButtons {
    display: flex;
}

.LocationLeafletPopup .LocationButtons * {
    margin: auto;
}