.NewCommentInputSection {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

.NewCommentInputSection button {
    margin-top: 5px;
}

.CommentSection {
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

.CommentSection .Comment:nth-child(even) {
    background-color: #fdfdfd;
}

.Comment {
    display: flex;
    white-space: pre-line;
}

.CommentAuthor {
    margin: auto;
    margin-left: 5px;
    padding: 10px 10px 10px 0px;
    max-width: 15%;
    min-width: 15%;
    overflow-x: hidden;
}

@media screen and (max-width: 500px) {
    .Comment {
        flex-direction: column;
    }
    .CommentAuthor {
        max-width: unset;
        min-width: unset;
    }
}

.CommentBody {
    padding: 10px;
    flex-grow: 1;
    overflow-x: hidden;
}

.CommentControls {
    flex-shrink: 1;
    margin-top: auto;
    display: flex;
}
