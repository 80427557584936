.MapButtons {
    position: absolute !important; 
    z-index: 1000;
    /* Needed to fix z index bug on ios. See: https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome */
    transform: translate3d(0, 0, 200px); 

    margin: 8px;

    bottom: 0px;
    right: 0px;
}

.RoundMapButton svg {
    color: rgb(73, 73, 73);
}

.RoundMapButton {
    border-radius: 50px;
    background-color: rgb(233, 233, 233) !important;
    margin: 4px;
}

.NonCreationButtons {
    display: flex;
    flex-direction: column;
}

.CreationButtons {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
}

.CreationButtons button {
    margin: 4px;
}

.CreationButtons .LatLngDisplay {
    font-size: 10px;
}