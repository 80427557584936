.SharingModal {
    padding: 10px;
}

.SharingUserCard {
    display: flex;
}

.SharingUserCard * {
    margin: auto;
}

.SharingModal .UserCards {
    margin: 10px;
    max-height: 400px;
    overflow-y: scroll;
}

.SharingModal .UserSearch {
    display: flex;
    margin: 10px;
}

.SharingModal .SharingSectionTitle {
    margin-top: 20px;
    margin-left: 10px;
}